<template>
  <div>
    <div class="row review-component">
      <b-card class="col-lg-12 main-content">
        <div class="panel-list" v-show="ClientRequest != null">
          <div class="panel-body">
            <b-row class="panel-title">
              <label :class="'col-'+ColumnsClass">Client Info</label>
            </b-row>
            <b-row class="panel-content">
              <b-col :cols="ColumnsClass">
                <label>HFM Client</label>
                <p>{{ ClientRequest.HFMClientName }}</p>
              </b-col>
              <!-- <b-col :cols="ColumnsClass">
                <label>MDM Client Name</label>
                <p>{{ ClientRequest.MDMClientName }}</p>
              </b-col> -->
              <b-col :cols="ColumnsClass">
                <label>Entity</label>
                <p>{{ ClientRequest.Entity }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Agency</label>
                <p>{{ ClientRequest.Agency }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Client Name</label>
                <p>{{ ClientRequest.ClientName }}</p>
              </b-col>
              <!-- <b-col :cols="ColumnsClass" v-if="IsTruncatedClientActivated">
                <label>Truncated Client Name</label>
                <p>{{ ClientRequest.TruncatedClientName }}</p>
              </b-col> -->
              <b-col :cols="ColumnsClass">
                <label>Requested Client Code</label>
                <p>{{ ClientRequest.RequestedClientCode }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Is this for a client code that is already on file that needs to be opened in an additional media?</label>
                <p>{{ ClientRequest.IsExistingClient }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>BPM Assigned</label>
                <p>{{ ClientRequest.BPMAssigned }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>DDS Media Office Code</label>
                <p>{{ ClientRequest.DDSMediaOfficeCode }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>DDS Accounting Office Code</label>
                <p>{{ ClientRequest.DDSAccountingOfficeCode }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>AX Office Location</label>
                <p>{{ ClientRequest.AXOfficeLocation }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="ClientRequest.ProductUDF1">
                <label>Product UDEF1</label>
                <p>{{ ClientRequest.ProductUDF1 }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="ClientRequest.ProductUDF2">
                <label>Product UDEF2</label>
                <p>{{ ClientRequest.ProductUDF2 }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="ClientRequest.EstimateUDF1">
                <label>Estimate UDEF1</label>
                <p>{{ ClientRequest.EstimateUDF1 }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="ClientRequest.EstimateUDF2">
                <label>Estimate UDEF2</label>
                <p>{{ ClientRequest.EstimateUDF2 }}</p>
              </b-col>
              <!-- <b-col :cols="ColumnsClass" v-show="true">
                <label>Special Requirement</label>
                <p>{{ ClientRequest.SpecialRequirement }}</p>
              </b-col> -->
            </b-row>
          </div>
          <div class="panel-body">
            <b-row class="panel-title">
              <label :class="'col-'+ColumnsClass">Euler Grade</label>
            </b-row>
            <b-row class="panel-content">
              <b-col :cols="ColumnsClass">
                <label>Euler Grade</label>
                <p v-if="AddOrUpdateEular">{{ ClientRequest.EulerGrade }}</p>
                <p v-else>-</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>IS THE MEDIA SPEND EXPECTED TO EXCEED THE EULER INSURANCE COVERAGE?</label>
                <p>{{ ClientRequest.IsMediaSpendExceedsInsuranceCoverage }}</p>
              </b-col>

              <b-col :cols="ColumnsClass" v-if="ClientRequest.ApprovalSource!=null">
                <label>Approval Source</label>
                <p v-if="AddOrUpdateEular">{{ GetEulerApprovalSourceOriginalValue() }}</p>
                <p v-else>-</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Attached File</label>
                <div v-if="AddOrUpdateEular && EulerFilesUploaded && EulerFilesUploaded.length > 0">
                   <div v-for="file in FilesUploaded('EulerFiles')" :key="file.Name">
                  <span v-if="file.FullName">
                    <a
                      class="file-ref"
                      :href="file.Name"
                      @click.prevent="downloadItem(file)"
                      target="_blank"
                      download
                      >{{ file.Name }}</a
                    >
                  </span>
                  <span v-else>
                    <span>{{ file.Name }}</span>
                  </span>
                </div>
                </div>
                <div v-else>-</div>

              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Client Entity Name Per Agreement</label>
                 <p v-if="AddOrUpdateEular">{{ ClientRequest.ClientEntityNamePerAgreement }}</p>
                 <p v-else>-</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Comments</label>
                 <p v-if="AddOrUpdateEular">{{ ClientRequest.Comments }}</p>
                 <p v-else>-</p>
              </b-col>
            </b-row>
          </div>
          <div class="panel-body">
            <b-row class="panel-title">
              <label :class="'col-'+ColumnsClass">Billing Info</label>
            </b-row>
            <b-row class="panel-content">
              <b-col :cols="ColumnsClass">
                <label>Biiling Party</label>
                <p>{{ ClientRequest.BillingParty }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Bill to Client</label>
                <p>{{ ClientRequest.ClientBillToName }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Country</label>
                <p>{{ ClientRequest.Country }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="!IsBillingCountryUSA">
                <label>Address Line 1</label>
                <p>{{ ClientRequest.AddressLine1 }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="!IsBillingCountryUSA">
                <label>Address Line 2</label>
                <p>{{ ClientRequest.AddressLine2 }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="IsBillingCountryUSA">
                <label>Street Address </label>
                <p>{{ ClientRequest.TruncatedBillingStreetAddress==null ? ClientRequest.ClientBillingStreetAddress :ClientRequest.TruncatedBillingStreetAddress }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="IsBillingCountryUSA">
                <label>City</label>
                <p>{{ ClientRequest.City }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="IsBillingCountryUSA">
                <label>State</label>
                <p>{{ ClientRequest.State }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-show="IsBillingCountryUSA">
                <label>Zip Code</label>
                <p>{{ ClientRequest.ZipCode }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Currency</label>
                <p>{{ ClientRequest.Currency }}</p>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>Default Payment Terms</label>
                <p>{{ ClientRequest.DefaultPaymentTerms }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-if="ScreenshotFilesUploaded && ScreenshotFilesUploaded.length > 0">
                <label>Approval Screenshot from Credit Voyager</label>
                  <div v-for="file in ScreenshotFilesUploaded" :key="file.Name">
                  <span v-if="file.FullName">
                    <a
                      class="file-ref"
                      :href="file.Name"
                      @click.prevent="downloadItem(file)"
                      target="_blank"
                      download
                      >{{ file.Name }}</a
                    >
                  </span>
                  <span v-else>
                    <span>{{ file.Name }}</span>
                  </span>
                </div>
              </b-col>
              <b-col :cols="ColumnsClass">
                <label>is this a COS2 Client</label>
                <p>{{ ClientRequest.IsThisCostToClient }}</p>
              </b-col>
               <b-col :cols="ColumnsClass" v-if="ClientRequest.ProgrammaticBundled!=null" >
                <label>is this client COS2 ONLY for media B- Programmatic Bundled </label>
                <p>{{ ClientRequest.ProgrammaticBundled }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-if="ClientRequest.AORClientCode">
                <label>AOR Client Code</label>
                <p>{{ ClientRequest.AORClientCode }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-if="ClientRequest.AORClientName">
                <label>AOR Client Name</label>
                <p>{{ ClientRequest.AORClientName }}</p>
              </b-col>
              <b-col :cols="ColumnsClass" v-if="ClientRequest.AORClientAgency">
                <label>AOR Client Agency</label>
                <p>{{ ClientRequest.AORClientAgency }}</p>
              </b-col>
            </b-row>
          </div>
          <div class="panel-body" v-if="BillingPartyNotTimeOnly">
            <b-row class="panel-title">
              <label class="col-lg-">Commission Info</label>
            </b-row>
            <b-row class="panel-content">

            <b-row class="col-lg-12"  v-for="item in CommissionInfoCategories"
                :key="item.MediaCategory">

           <b-col v-if="item.MediaCategory == 'Productionbilling'">
             <p> Production Billing Only </p>
           </b-col>

            <b-col v-else>
            <b-col cols="lg-12">
              <div>{{ item.MediaCategory }}:</div>
                </b-col>
              <b-col
                cols="lg-4"
                v-for="mediatype in item.Media"
                :key="mediatype.MediaType"
              >
                <label>{{ mediatype.MediaType }}</label>
                <div v-if="5">
                  <div v-if="mediatype.MediaType">Fee Only :
                    <span v-if="mediatype.FeeOnly==true">Yes</span>
                    <span v-if="mediatype.FeeOnly==false">No</span>
                  </div>
                  <div v-if="mediatype.FeeOnly==false">
                    Commission %: {{ mediatype.CommissionPercentage }}%
                  </div>
                  <div v-if="mediatype.FeeOnly == true">
                    Commission %: -
                  </div>
                  <div v-if="mediatype.FeeOnly==false">
                    Commission Basis: {{ mediatype.CommissionBasis }}
                  </div>
                  <div v-if="mediatype.FeeOnly == true">
                    Commission Basis: -
                  </div>
                  <div v-if="ClientRequest.DefaultPaymentTerms && ClientRequest.DefaultPaymentTerms.split('-')[1].trim().toString() != mediatype.DefaultPaymentTerms.trim().toString()">
                    Default PaymentTerms : {{ mediatype.DefaultPaymentTerms }}
                  </div>
                </div>
              </b-col>
              </b-col>

              </b-row>
            </b-row>
            <b-row class="panel-content" v-if="CreditVoyagerApprovalFilesUploaded && CreditVoyagerApprovalFilesUploaded.length > 0">
                <b-col :cols="ColumnsClass">
                  <label>Were the Payment terms above N030 approved by the Network CFO?</label>
                  <p>true</p>
                </b-col>
                <b-col :cols="ColumnsClass">
                  <label>Credit Voyager Approval for Payment terms</label>
                    <div v-for="file in CreditVoyagerApprovalFilesUploaded" :key="file.Name">
                    <span v-if="file.FullName">
                        <a
                        class="file-ref"
                        :href="file.Name"
                        @click.prevent="downloadItem(file)"
                        target="_blank"
                        download
                        >{{ file.Name }}</a
                      >
                    </span>
                    <span v-else>
                      <span>{{ file.Name }}</span>
                    </span>
                  </div>
                </b-col>
              </b-row>
          </div>
          <div class="panel-body">
            <b-row class="panel-title">
              <label :class="'col-'+ColumnsClass">Product Info</label>
            </b-row>
            <b-row
              class="panel-content row"
              v-if="ClientRequest.ProductDetails"
            >
              <b-col
                cols="lg-4"
                v-for="product in ClientRequest.ProductDetails"
                :key="product.ProductName"
              >
                <label>Product Name: {{ product.ProductName }}</label>
                <div>Product Code: {{ product.ProductCode }}</div>
                <div
                  v-if="
                    product.ProductCommissionDetails != null &&
                    product.ProductCommissionDetails.length > 0
                  "
                >
                  Media:
                  {{
                    product.ProductCommissionDetails.map(
                      (s) => s.MediaType
                    ).join(",")
                  }}
                </div>
                <div v-if="IsProductExceptionsAdded(product)">
                  <div>
                    <!-- Exceptions: -->
                    <div v-html="GetCommissionException(product)"></div>
                    <div v-html="GetBillingException(product)"></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="panel-body" v-if="ClientRequest.SpecialRequirement">
            <b-row class="panel-title">
              <label :class="'col-'+ColumnsClass">Special Requirement</label>
            </b-row>
            <b-row
              class="panel-content row"
              v-if="ClientRequest.SpecialRequirement"
            >
              <b-col
                cols="lg-4">
                <label>{{ ClientRequest.SpecialRequirement }}</label>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { NETMedia, SPOTMedia,PRINTCategoryMedia,DIGITALMedia } from "../../utilities/MediaList";
export default {
  name: "Overview",
  props: {
    ClientRequest: {
      required: false,
      type: Object,
    },
    DownloadableFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
    ID:{
      type:Number,
      required:false,
      default:0
    },
    Columns:{
      type:Number,
      default:4,
      required:false,
    }
  },
  data() {
    return {
      USACountry: "United States",
      CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
      Files: [],
      BillingStreetAddressLength:26,
      FilesLoaded:false,
    };
  },
  created(){
      if (this.ID && parseInt(this.ID) > 0 && this.DownloadableFiles && this.FilesLoaded==false) {
        this.FilesLoaded=true;
        this.LoadFiles();
      }
  },
  watch: {
    "ClientRequest.ID": function (val) {
      if (val && parseInt(val) > 0 && this.DownloadableFiles && this.FilesLoaded==false) {
        this.FilesLoaded=true;
        this.LoadFiles();
      }
    },
  },
  computed: {
    ColumnsClass(){
     return `lg-${this.Columns}`;
    },
    BillingPartyNotTimeOnly(){

      let timeOnlyOptions=["Time Only (Client Related Time MED)","Time Only (Internal Project Related Time ADM)"];
      //let timeOnlySelected=timeOnlyOptions.includes(this.BillingInfo.BillingParty);
      return !(this.ClientRequest && timeOnlyOptions.includes(this.ClientRequest.BillingParty));
    },
    CommissionInfoCategories(){
     if(this.ClientRequest!=null &&this.ClientRequest.ClientMediaCommissions!=null && this.ClientRequest.ClientMediaCommissions.length>0){
      let commissionMediaTypes=[];

      if(this.ClientRequest.ClientMediaCommissions.length==1 && this.ClientRequest.ClientMediaCommissions[0].ProductionBilling )
      {
          commissionMediaTypes.push({MediaCategory:'Productionbilling',Media:[]});
      }
      else
      {
      let spotMedia=this.ClientRequest.ClientMediaCommissions.filter(val=>{
     return SPOTMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });

      if(spotMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Spot',Media:spotMedia});
      }

      let netMedia=this.ClientRequest.ClientMediaCommissions.filter(val=>{
     return NETMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });

      if(netMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Network',Media:netMedia});
      }

      let printMedia=this.ClientRequest.ClientMediaCommissions.filter(val=>{
     return PRINTCategoryMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });

      if(printMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Print',Media:printMedia});
      }

      let digitalMedia=this.ClientRequest.ClientMediaCommissions.filter(val=>{
     return DIGITALMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });

      if(digitalMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Digital',Media:digitalMedia});
      }

    }
      return commissionMediaTypes;

     }else{
       return [];
     }
    },
    AddOrUpdateEular(){
     return this.ClientRequest.AddOrUpdateEular;
    },
    EulerFilesUploaded(){
      return this.FilesUploaded('EulerFiles');
    },
    ScreenshotFilesUploaded(){
      return this.FilesUploaded('ScreenshotFiles');
    },
    CreditVoyagerApprovalFilesUploaded(){
      return this.FilesUploaded('CreditVoyagerApprovalFiles');
    },
    IsBillingCountryUSA() {
      return this.ClientRequest.Country == this.USACountry;
    },
    IsTruncatedClientActivated() {
      return (
        this.ClientRequest != null &&
        this.ClientRequest.TruncatedClientName != null &&
        this.ClientRequest.TruncatedClientName != ""
      );
    },
  },
  methods: {
    async downloadItem(file) {
      this.$store
        .dispatch("client/DownloadFile", file)
        .then((response) => {
          const blob = new Blob([response.data], { type: file.Extension });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.Name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((ex) => {
          if (ex.response) {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex.response.data}`
            );
          } else {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex}`
            );
          }
        });
    },
    async LoadFiles() {
      this.$store
        .dispatch("client/RenderUploadedFiles", parseInt(this.ID))
        .then((res) => {
          if (res) {
            this.Files = res.data;
          } else {
            this.Files = [];
          }
        })
        .catch(() => {
          this.$store.dispatch(
            "progress/setErrorMessage",
            "Error while loading uploaded files"
          );
        });
    },
    IsProductExceptionsAdded(product) {
      return (
        product != null &&
        (product.ProductBillingAddress != null ||
          product.CommissionBasisID != null ||
          product.FeeOnly != null ||
          product.TimeOnly != null)
      );
    },

    GetEulerApprovalSourceOriginalValue(){
      if(this.ClientRequest.ApprovalSource =="1")
      {
          return "Network CEO";
      }
      else if(this.ClientRequest.ApprovalSource =="2")
      {
          return "Network CFO";
      }
      else
      {
          return "Omnicom CFO";
      }
    },

    IsBillingExceptionAdded(product) {
      return product != null && product.ProductBillingAddress != null;
    },
    IsCommissionExceptionAdded(product) {
      return (
        product != null &&
        (product.CommissionBasisID != null || product.FeeOnly != null)
      );
    },
    GetCommissionException(product) {
      let html = "";
      if (
        product.CommissionBasisID != null ||
        product.FeeOnly != null ||
        product.TimeOnly != null
      ) {
        if (product.TimeOnly) {
          html += `Time Only`;
        } else if (product.CommissionBasisID == 1) {
          html += `Commission Basis: Net - ${product.CommissionPercentage}%`;
        } else if (product.CommissionBasisID == 2) {
          html += `Commission Basis: Gross - ${product.CommissionPercentage}%`;
        } else if (product.FeeOnly) {
          html += `Fee Only`;
        }
      }
      return html;
    },
    GetBillingException(product) {
      let html = "";
      if (product.ProductBillingAddress != null) {
        if (product.ProductBillingAddress.Country != this.USACountry) {
          html += `Billing Address: ${product.ProductBillingAddress.AddressLine1} ${product.ProductBillingAddress.AddressLine2}`;
        } else {
          let billingAdddress=product.ProductBillingAddress.BillingStreetAddress.length>this.BillingStreetAddressLength &&product.ProductBillingAddress.TruncatedBillingStreetAddress ?product.ProductBillingAddress.TruncatedBillingStreetAddress:product.ProductBillingAddress.BillingStreetAddress;
          html += `Billing Address: ${billingAdddress} <div> ${product.ProductBillingAddress.City},${product.ProductBillingAddress.State},${product.ProductBillingAddress.ZipCode} </div>`;
        }
      }
      return html;
    },
    FilesUploaded(fileCategory) {
      let files = [];
      if (this.DownloadableFiles) {
        this.Files.filter(file => file.Category == fileCategory).forEach((element) => {
          files.push(element);
        });
      } else {
        this.ClientRequest.Files.filter(file => file.Category == fileCategory).forEach((element) => {
          files.push(element);
        });
      }
      return files;
    },
  },
};
</script>

<style scope >
.file-ref {
  color: #007bff !important;
}
.panel-list:not(.media-dropdowns) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.panel-list .panel-body .panel-title {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  width: 100%;
  margin-left: 0px;
  margin-bottom: 25px !important;
}
.panel-list .panel-body .panel-title label {
  color: #77a2bb;
  margin-bottom: 0px !important;
  padding-top: 13px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  padding-left: 0px !important;
  min-height: 35px;
}
.panel-list .panel-body .panel-title .btn {
  padding-left: 5px;
  padding-right: 5px;
}
.panel-list .panel-body .panel-title > div {
  padding-right: 0px !important;
  float: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}
.panel-list .panel-content label {
  text-transform: uppercase;
  color: #2c3865;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding-left: 0px;
}
.panel-list .panel-content p {
  text-transform: uppercase;
  color: #4d5161;
  font-size: 14px;
}
.panel-content.row > div > label:first-child {
  color: #2c3865 !important;
  font-family: "Open Sans" !important;
  font-size: 12px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}
.panel-content.row > div > *:not(:first-child) {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
}
.panel-content.row > div > div {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.panel-list > .panel-body {
  margin-top: 1rem !important;
}
.panel-content.row > div {
  margin-bottom: 10px !important;
}
.edit-icon {
  height: 11px;
  width: 11px;
  color: #00a1d2;
}
.request-status {
  background-color: rgba(216, 216, 216, 0.2);
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  height: 135px;
  border-radius: 5px;
}
.status-bar {
  line-height: 2;
  color: #d5d7e0;
}
.request-status .status {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.request-status .date {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #a2a9ad;
}
.request-status .updatedby {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.request-status .comments {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #00a1d2;
}
</style>